export class Product {
    $key?: string;
    // id?: string;
    name: string;
    shortDesc?: string;
    longDesc?: string;
    brand?: string;
    brandurl?: string;
    aplications?: any;
    category?:string;
    relevance?: number;
    imageurl?: string;
    videourl?: string;
}
